// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDV7Sj3w08HK77lwl1pTY30OOlVzgRmzHo",
  authDomain: "tgwebapp-dc589.firebaseapp.com",
  projectId: "tgwebapp-dc589",
  storageBucket: "tgwebapp-dc589.appspot.com",
  messagingSenderId: "222225450202",
  appId: "1:222225450202:web:277438664377a09675d1e3",
  measurementId: "G-0PKCMJ7BDB"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db, collection, addDoc };