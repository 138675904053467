<template>
  <v-app class="app-layout">
    <snack-bar ref="snackbar"></snack-bar>

    <v-app-bar fixed flat dense>
      <div>
        <router-link to="/home">
        <icon-base width="18" height="18" class="mr-2">
          <icon-check-square-logo />
        </icon-base>
        <span class="logo-txt font-weight-light primary--text text--darken-1"
          >AVTOMAT</span
        >
        
      </router-link>
      </div>
      <v-spacer></v-spacer>
      <div>
        <!-- <router-link class="mr-4" to="/admin/index">Admin Panel</router-link> -->
        <router-link class="number-text" to="/home">{{firstname}}</router-link>
      </div>
    </v-app-bar>

    <v-main class="mt-14 main-content" >
      <transition :name="transitionName" mode="out-in">
        <router-view></router-view>
      </transition>
      <v-fab-transition>
                    <v-btn
                      :color="isDarkTheme ? 'warning': 'darkt'"
                      fab
                      x-small
                      dark
                      bottom
                      left
                      @click="toggleTheme"
                      class="v-btn--floating"
                    >
                      <v-icon v-if="isDarkTheme">mdi-white-balance-sunny</v-icon>
                      <v-icon v-else>mdi-weather-night</v-icon>
                    </v-btn>
                  </v-fab-transition>
    </v-main>


  </v-app>
</template>

<script>
import SnackBar from "@/components/SnackBar";
import { mapGetters } from "vuex";
import WebApp from "@twa-dev/sdk";
import { db } from "../firebase"; // Make sure to import your Firestore instance
import { getDoc, setDoc, doc, where, collection, addDoc } from "firebase/firestore";

export default {
  components: {
    SnackBar,
  },

  data() {
    return {
      transitionName: "",
      firstname: "",
      userData: {},
      isDarkTheme: this.$vuetify.theme.dark,
    };
  },

  mounted() {
    this.transitionName = "fade";
    // this.firstname = WebApp.initDataUnsafe.user.id;some
    this.initializeTelegramWebApp();
    // this.writeUserDataToFirestore();
  },

  computed: mapGetters({
    user: "auth/user",
  }),

  methods: {
    toggleTheme() {
      // Toggle the theme between light and dark
      this.isDarkTheme = !this.isDarkTheme;
      this.$vuetify.theme.dark = this.isDarkTheme;

      // Save the selected theme to localStorage
      localStorage.setItem('userTheme', this.isDarkTheme ? 'dark' : 'light');
    },
    logout() {
      this.$store.dispatch("auth/logout");
    },
    initializeTelegramWebApp() {
      try {
        const data = WebApp.initDataUnsafe;
        const userData = data.user || {};

        // Write user data to Firestore
        if (userData.id) {
          this.writeUserDataToFirestore(userData);
        }
      } catch (error) {
        console.error('Failed to initialize Telegram Web App:', error);
      }
    },
    async writeUserDataToFirestore(user) {
      console.log('Funksiyaga kirdi');
      this.firstname = user.first_name;
      // this.firstname = user.first_name;
      try {
        const userDocRef = doc(db, "users", String(user.id));
        const userDoc = await getDoc(userDocRef);  // Await the async operation
        console.log(userDoc);

        // If the document doesn't exist, add the user
        if (!userDoc.exists()) {
          await setDoc(userDocRef, {
            first_name: user.first_name,
            id: String(user.id),
            timestamp: new Date(),
          });
          // console.log("User added with ID: ", user.id);
        } else {
          // console.log("User already exists in the database, not adding.");
        }
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }

  },
};
</script>

<style scoped>
/* .theme--light.v-bottom-navigation {
  background-color: #c8c9cd !important;
}
.theme--light.v-bottom-navigation .v-btn:not(.v-btn--active) {
    color: rgb(195 202 224 / 87%) !important;
}
.v-item-group.v-bottom-navigation .v-btn.v-btn--active {
    color:  rgb(219, 225, 244) !important;;
}
.theme--light.v-btn.v-btn--has-bg {
    background-color: transparent !important;
}
.footer-navigation {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
} */
/* .main-content {
  background-color: rgb(18, 21, 78);
  color: rgba(255, 255, 255, 0.778);
} */
.v-btn--floating {
    position: fixed; 
    right: 16px; 
    top: 42%; 
    transform: translateY(-50%);

  }
</style>
