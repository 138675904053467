import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)
const userSelectedTheme = localStorage.getItem('userTheme') === 'dark';
export default new Vuetify({
  theme: {
    dark: userSelectedTheme,
    themes: {
      light: {
        background: '#f9f9f9',
        primary: '#0f5f84',
        secondary: '#623827',
        accent: '#9783f3',
        error: '#F56C6C',
        info: '#909399',
        success: '#17c3b2',
        warning: '#E6A23C',
        borderc: '#c6c9cf',
        darkt: '453F78',
      },
      dark: {
        primary: '#fff',
        background: '#453F78',
        borderc: '#f9f9f9'
      },
    },
    options: { customProperties: true },
  }
})
